import {observer} from "mobx-react-lite";
import {Autocomplete, Button, Checkbox, Grid, TextField, Typography} from "@mui/material";
import {CheckBox, CheckBoxOutlineBlank} from "@mui/icons-material";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import {BagAdvancedInsightsManager} from "../../stores/managers/BagAdvancedInsightsManager";
import {countriesToContinents, Country, countryToContinent} from "../../components/visualization/bubble-map/countries";
import {SearchSupplierAutocomplete} from "./SearchSupplierAutocomplete";
import React, {ReactNode} from "react";
import {useStores} from "../../stores";
import {SearchParentSupplierAutocomplete} from "./SearchParentSupplierAutocomplete";
import {routes} from "../../routing/routes";
import {useLocation, useParams} from "react-router-dom";
import {ShadcnDateRangePicker} from "../../shadcn/components/ui/ShadcnDateRangePicker";
import moment from "moment";
import {C} from "../../configurations/active-configuration";
import {SearchBusinessUnitAutocomplete} from "./SearchBusinessUnitAutocomplete";
import {SearchCDescriptionAutocomplete} from "./SearchCDescriptionAutocomplete";
import {environment} from "../../env";
import {CategoryFilter} from "../../services/ApiTypes";

type Props = {
    showL4: boolean
}
export const AdvancedDashboardHeaderFilters: React.FC<Props> = observer(({showL4}) => {
    const {p, bagStore} = useStores();
    const params = useParams();
    const location = useLocation();
    // const isMergeXMergePage = location.pathname === routes.merge_x_merge_advanced_dashboard;
    const isMergeXCSingleDashboard = environment.package === 'merge_x_cleanup' && location.pathname === routes.merge_xc_single_advanced_dashboard;
    const hideParents = p.p.hideParentSupplierOpportunityDashboard || isMergeXCSingleDashboard;

    const filterItems: (ReactNode | undefined)[] = [
        <ShadcnDateRangePicker
            onUpdate={(values) => {
                const startDate = moment(values.range.from).format('YYYY-MM-DD'); // Convert from Date to formatted string
                const endDate = moment(values.range.to).format('YYYY-MM-DD'); // Convert to Date to formatted string, if available
                console.log('startDate', startDate, values.range.from)
                console.log('endDate', endDate, values.range.to)
                bagStore.advanced.dateFilterChanged(startDate, endDate); // Call your dateFilterChanged function with the formatted dates
            }}
            dateFrom={bagStore.advanced.filters && bagStore.advanced.filters.date_range && bagStore.advanced.filters.date_range[0]}
            dateTo={bagStore.advanced.filters && bagStore.advanced.filters.date_range && bagStore.advanced.filters.date_range[1]}
            align="start"
        />,
        <Autocomplete
            className="adv-dashboard-country-filter"
            multiple
            fullWidth
            limitTags={1}
            value={bagStore.advanced.filters.country || []}
            options={bagStore.advanced.geoDataCountryStringArr}
            disableCloseOnSelect
            getOptionLabel={(option) => option ? option : '-'}
            renderOption={(props, option, {selected}) =>
                <li {...props}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small"/>}
                        checkedIcon={<CheckBox fontSize="small"/>}
                        style={{marginRight: 8}}
                        checked={selected}
                    />

                    <Grid container>
                        <Grid item xs={2}>
                            {option.toLowerCase() && <img
                                crossOrigin="anonymous"
                                loading="lazy"
                                width="20"
                                srcSet={`https://flagcdn.com/w40/${option.toLowerCase()}.png 2x`}
                                src={`https://flagcdn.com/w20/${option.toLowerCase()}.png`}
                                alt=""
                            />}
                        </Grid>
                        <Grid item xs={5} style={{display: 'flex', alignItems: 'center'}}>
                            {option ? option : '-'}
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={4} style={{display: 'flex', alignItems: 'center'}}>
                            <CurrencyComponent
                                v={BagAdvancedInsightsManager.getCountrySpend(bagStore.advanced.geoData, option)}
                                nDigits={3}/>
                        </Grid>
                    </Grid>
                </li>
            }
            onChange={(_, value) => {
                bagStore.advanced.countryFilterChangedNew(value);
            }}
            renderInput={(params) => (
                <TextField {...params} label="Supplier country"
                           placeholder={(bagStore.advanced.filters.country?.length ?? 0) > 0 ? '' : 'All'}/>
            )}
        />,
        <Autocomplete
            className="adv-dashboard-filter-continent"
            multiple
            fullWidth
            limitTags={1}
            value={countriesToContinents(bagStore.advanced.filters.country as Country[] || [])}
            options={bagStore.advanced.continentFilterData}
            disableCloseOnSelect
            getOptionLabel={(option) => option ? option : '-'}
            renderOption={(props, option) => {
                const s = bagStore.advanced.filters.country && bagStore.advanced.filters.country.some((selectedCountry) => {
                    const continent = countryToContinent(selectedCountry as Country);
                    return continent === option;
                });
                return (
                    <li {...props}>
                        <Checkbox
                            icon={<CheckBoxOutlineBlank fontSize="small"/>}
                            checkedIcon={<CheckBox fontSize="small"/>}
                            style={{marginRight: 8}}
                            checked={s}
                        />
                        <Grid container>
                            <Grid item xs={6}>
                                {option ? option : '-'}
                            </Grid>
                            <Grid item xs={6}>
                                {/*TODO: No calculation in render thread*/}
                                <CurrencyComponent
                                    v={BagAdvancedInsightsManager.calculateContinentSpend(bagStore.advanced.geoData, option)}
                                    nDigits={3}/>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
            onChange={(_, i) => {
                const newCountries = i.flatMap((continent) => {
                    return bagStore.advanced.geoData.filter((country) => {
                        return countryToContinent(country.country) === continent;
                    }).map((country) => country.country);
                });
                bagStore.advanced.countryFilterChanged(newCountries);
            }}
            renderInput={(params) => (
                <TextField {...params} label="Supplier continent"
                           placeholder={(bagStore.advanced.filters.country?.length ?? 0) > 0 ? '' : 'All'}/>
            )}
        />,
        C.profile.disableAdvDashboardSearchBySupplier ? undefined : <SearchSupplierAutocomplete
            wrapperClassName="supplier-search-autocomplete-wrapper autocomplete-wrapper" limitTags={1}
        />,
        hideParents ? undefined : <SearchParentSupplierAutocomplete
            wrapperClassName="parent-supplier-search-autocomplete-wrapper autocomplete-wrapper" limitTags={1}
        />,
        p.p.synergyDashboardShowBusinessUnits ? <>
            <Typography variant="h6" align="left" style={{marginBottom: '1em', color: '#193150'}}
                        className="font-weight-bold key-value">
                Filter by BU
            </Typography>
            <SearchBusinessUnitAutocomplete
                wrapperClassName="business-unit-search-autocomplete-wrapper autocomplete-wrapper"
                limitTags={1}
                label="Business Unit"
                noOptionsText="No business units found"
            />
        </> : undefined,
        p.p.synergyDashboardShowCDescriptionFilter ? <>
            <Typography variant="h6" align="left" style={{marginBottom: '1em', color: '#193150'}}
                        className="font-weight-bold key-value">
                Filter by Company
            </Typography>
            <SearchCDescriptionAutocomplete
                wrapperClassName="c-description-search-autocomplete-wrapper autocomplete-wrapper"
                limitTags={1}
                label="Company"
                noOptionsText="No company description found"
            />
        </> : undefined,
        <Autocomplete
            options={bagStore.advanced.spendPerActiveL1DataStringArr}
            value={bagStore.advanced.filters.category?.[0] || ''}
            disableCloseOnSelect
            getOptionLabel={(option) => option ? option : ' Select L1'}
            renderOption={(props, option) => (
                <li {...props}>
                    <Grid container>
                        <Grid item xs={6}>
                            {option ? option : '-'}
                        </Grid>
                        <Grid item xs={6}>
                            <CurrencyComponent v={bagStore.advanced.getActiveL1Spend(option)} nDigits={3}/>
                        </Grid>
                    </Grid>
                </li>
            )}
            onChange={(_, value) => {
                if (value) {
                    const categoryL1 = [value] as CategoryFilter;
                    bagStore.advanced.categoryFilterChanged(categoryL1);
                } else {
                    bagStore.advanced.categoryFilterChanged(null);
                }
            }}
            renderInput={(params) => (
                <TextField {...params} label=" Filter L1"/>
            )}
        />,
        <Autocomplete
            options={bagStore.advanced.spendPerActiveL2DataStringArr}
            disabled={bagStore.advanced.filters.category?.[0] === undefined}
            value={bagStore.advanced.filters.category?.[1] || ''}
            disableCloseOnSelect
            getOptionLabel={(option) => option ? option : ' Select L2'}
            renderOption={(props, option) => (
                <li {...props}>
                    <Grid container>
                        <Grid item xs={6}>
                            {option ? option : '-'}
                        </Grid>
                        <Grid item xs={6}>
                            <CurrencyComponent v={bagStore.advanced.getActiveL2Spend(option)} nDigits={3}/>
                        </Grid>
                    </Grid>
                </li>
            )}
            onChange={(_, value) => {
                if (value) {
                    const categoryL2 = [bagStore.advanced.filters.category?.[0], value] as CategoryFilter;
                    bagStore.advanced.categoryFilterChanged(categoryL2);
                } else {
                    const categoryL1 = [bagStore.advanced.filters.category?.[0]] as CategoryFilter;
                    bagStore.advanced.categoryFilterChanged(categoryL1);
                }

            }}
            renderInput={(params) => (
                <TextField {...params} label=" Filter L2"/>
            )}
        />,
        <Autocomplete
            options={bagStore.advanced.spendPerActiveL3DataStringArr}
            disabled={bagStore.advanced.filters.category?.[1] === undefined}
            value={bagStore.advanced.filters.category?.[2] || ''}
            disableCloseOnSelect
            getOptionLabel={(option) => option ? option : ' Select L3'}
            renderOption={(props, option) => (
                <li {...props}>
                    <Grid container>
                        <Grid item xs={6}>
                            {option ? option : '-'}
                        </Grid>
                        <Grid item xs={6}>
                            <CurrencyComponent v={bagStore.advanced.getActiveL3Spend(option)} nDigits={3}/>
                        </Grid>
                    </Grid>
                </li>
            )}
            onChange={(_, value) => {
                if (value) {
                    const categoryL3 = [bagStore.advanced.filters.category?.[0], bagStore.advanced.filters.category?.[1], value] as CategoryFilter;
                    bagStore.advanced.categoryFilterChanged(categoryL3);
                } else {
                    const categoryL2 = [bagStore.advanced.filters.category?.[0], bagStore.advanced.filters.category?.[1]] as CategoryFilter;
                    bagStore.advanced.categoryFilterChanged(categoryL2);
                }
            }}
            renderInput={(params) => (
                <TextField {...params} label=" Filter L3"/>
            )}
        />,
        !showL4 ? undefined : <Autocomplete
            options={bagStore.advanced.spendPerActiveL4DataStringArr}
            disabled={bagStore.advanced.filters.category?.[2] === undefined}
            value={bagStore.advanced.filters.category?.[3] || ''}
            disableCloseOnSelect
            getOptionLabel={(option) => option ? option : ' Select L4'}
            renderOption={(props, option) => (
                <li {...props}>
                    <Grid container>
                        <Grid item xs={6}>
                            {option ? option : '-'}
                        </Grid>
                        <Grid item xs={6}>
                            <CurrencyComponent v={bagStore.advanced.getActiveL4Spend(option)} nDigits={3}/>
                        </Grid>
                    </Grid>
                </li>
            )}
            onChange={(_, value) => {
                if (value) {
                    const categoryL4 = [bagStore.advanced.filters.category?.[0], bagStore.advanced.filters.category?.[1], bagStore.advanced.filters.category?.[2], value] as CategoryFilter;
                    bagStore.advanced.categoryFilterChanged(categoryL4)
                } else {
                    const categoryL3 = [bagStore.advanced.filters.category?.[0], bagStore.advanced.filters.category?.[1], bagStore.advanced.filters.category?.[2]] as CategoryFilter;
                    bagStore.advanced.categoryFilterChanged(categoryL3)
                }
            }}
            renderInput={(params) => (
                <TextField {...params} label=" Filter L4"/>
            )}
        />,
        <Button fullWidth variant="outlined" onClick={() => bagStore.advanced.reset()}
                disabled={bagStore.advanced.resetDisabled}
                className="h-100">
            Reset all filters
        </Button>,
    ]

    const items = filterItems.filter(n => n !== undefined) as ReactNode[];
    return <Grid container spacing={3} className="px-2 mb-4">
        {items.map((item, j) =>
            <Grid item xs={3} key={j}>
                {item}
            </Grid>
        )}
    </Grid>;
    // const renderItems: ReactNode[][] = items.length > 6 ? [items.slice(0, 4), items.slice(4)] : [items]
    // return <>
    //     {renderItems.map((rowItems, i) =>
    //         <Grid key={i} container
    //               className={`filter-container header-filter-container header-filter-container-row-${i}`}>
    //             {rowItems.map((item, j) =>
    //                 <Grid item key={j}>
    //                     {item}
    //                 </Grid>
    //             )}
    //         </Grid>
    //     )}
    // </>;
})
