import {
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography
} from "@mui/material";
import React, {useEffect} from "react";
import './AdvancedTable.scss';
import * as d3 from "d3";
import {CurrencyComponent} from "../currency-component/CurrencyComponent";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import {AdvancedSupplierBreakdownData} from "../../services/ApiTypes";
import {LoadingDataWrapper} from "../../utils/LoadingDataWrapper";
import {observer} from "mobx-react-lite";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../constants";
import {getBusinessUnitIdHack, getSupplierIdHack} from "../../stores/hacks/display";
import {useStores} from "../../stores";

export type HeaderSpecification = {
    label: string
    key: string
    type: 'string' | 'number' | 'currency' | '2_decimal' | 'category' | 'selection' | 'string[]'
    onOrder?: () => void
};

type Props = {
    headers: Array<HeaderSpecification>,
    className?: string
    dataWrapper: LoadingDataWrapper<any>
    pageSize?: number
    pageSizeOptions?: Array<number>
    breakdownFilterChanged?: (item: AdvancedSupplierBreakdownData) => void
    container?: boolean
    ordering?: string
    rowSpan?: boolean
};
export const AdvancedTable: React.FC<Props> = observer((
    {
        headers,
        className,
        dataWrapper,
        pageSize,
        pageSizeOptions,
        breakdownFilterChanged,
        container,
        ordering,
        rowSpan
    }) => {
    const {bagStore} = useStores();
    const containerComponent = container ? Paper : 'div';
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(pageSize || 12);
    const [rowsPerPageOptions] = React.useState(pageSizeOptions || [5, 12, 25, 50]);
    const data = dataWrapper.elements;
    const isLoading = dataWrapper.loading;
    const slicedData = rowsPerPage > 0
        ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : data

    // Reset the current page number, when data the changes
    useEffect(() => {
        if (page === 0) return;
        if (isLoading) setPage(0)
        const maxPage = Math.floor(data.length / rowsPerPage);
        if (page > maxPage) {
            setPage(0)
        }
    }, [data.length, rowsPerPage, page, isLoading]);

    const colorScalesMap = new Map()
    headers.filter(h => h.type === 'currency').forEach(h => {
        const values: number[] = data.map(d => d[h.key]);
        colorScalesMap[h.key] = {
            positive: d3.scaleLinear([0, Math.max(1, ...values)], ['whitesmoke', '#193150']),
            negative: d3.scaleLinear([0, Math.min(-1, ...values)], ['whitesmoke', '#E62D15']),
        };
    });

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const tableClass = 'advanced-table-wrapper'
        + (className ? ` ${className}` : '')
        + (isLoading ? ' loading' : '');

    function order(headerKey: string, ordering?: string) {
        if (ordering === undefined) return false;
        if (ordering === headerKey) return 'asc';
        if (ordering.startsWith('-') && ordering.substring(1) === headerKey) return 'desc';
        return false;
    }

    function getCellSpec(header: HeaderSpecification, item: any) {
        let value;
        if (header.key === 's_id') {
            value = getSupplierIdHack(item);
        } else if (header.key === 'bu_id') {
            value = getBusinessUnitIdHack(item);
        } else if (header.key === 'p_src_databag_id') {
            value = bagStore.getBagName(item['p_src_databag_id']);
        } else {
            value = item[header.key]
        }
        let cellNumberClass = "";
        let className = "";
        let cellContent: JSX.Element
        switch (header.type) {
            case 'currency':
                cellContent = <CurrencyComponent v={value}/>;
                cellNumberClass = "fit-number";
                break;
            case '2_decimal':
                cellContent = <>{(Math.round(value * 100) / 100).toFixed(2)}</>;
                break;
            case 'category':
                cellContent = <>{value === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : value}</>;
                break;
            case 'string[]':
                cellContent = <>{Array.isArray(value) ? (value as string[]).join(', ') : value}</>;
                break;
            default:
                cellContent = <>{value}</>;
                break;
        }
        return [value, cellNumberClass, className, cellContent];
    }

    function getRowSpan(header: HeaderSpecification, value: any, nextIndex: number, stopAtTwo = false) {
        if (rowSpan && (header.type === 'category' || header.type === 'string[]') &&
            nextIndex < slicedData.length && value === getCellSpec(header, slicedData[nextIndex])[0]) {
            if (stopAtTwo) return 2;
            return getRowSpan(header, value, nextIndex + 1, stopAtTwo) + 1;
        }
        return 1;
    }

    return <TableContainer component={containerComponent} className={tableClass}>
        <Table cellPadding={0}>
            <TableHead>
                <TableRow>
                    {headers.map(header =>
                        header.onOrder
                            ? <TableCell key={header.key} sortDirection={order(header.key, ordering)}>
                                <TableSortLabel
                                    active={order(header.key, ordering) !== false}
                                    direction={order(header.key, ordering) || undefined}
                                    onClick={header.onOrder}
                                >
                                    {header.label}
                                </TableSortLabel>
                            </TableCell>
                            : <TableCell key={header.key}>
                                {header.label}
                            </TableCell>
                    )}
                </TableRow>
                <tr className={'progress-row' + (isLoading ? ' loading' : '')}>
                    <td colSpan={headers.length}>
                        {isLoading && <LinearProgress variant="indeterminate"/>}
                    </td>
                </tr>
            </TableHead>
            <TableBody>
                {slicedData.map((item, item_index) =>
                    <TableRow
                        hover
                        key={JSON.stringify(item)} // TODO: This is not a good key but okay for small cells
                        className={'advanced-table-row row-' + (item_index % 2 ? 'odd' : 'even')}
                        onClick={() => {
                            setPage(0)
                            breakdownFilterChanged?.(item)
                        }}>
                        {headers.map((header, header_index) => {
                            const cellSpecs = getCellSpec(header, item);
                            let style = {};
                            if (colorScalesMap[header.key]) {
                                const {positive, negative} = colorScalesMap[header.key];
                                const bgColor = cellSpecs[0] >= 0 ? positive(cellSpecs[0]) : negative(cellSpecs[0]);
                                const fgColor = d3.hsl(bgColor).l > 0.5 ? 'black' : 'white';
                                style['backgroundColor'] = bgColor;
                                style['color'] = fgColor;
                            }
                            // Find if item should have rowSpan, and use pivoting if it does
                            if (item_index > 0 && getRowSpan(header, cellSpecs[0], item_index - 1, true) > 1)
                                return <></>;
                            const row_span = getRowSpan(header, cellSpecs[0], item_index + 1);
                            return <TableCell
                                key={header.key}
                                rowSpan={row_span}
                                className={cellSpecs[2] + " advanced-table-cell" + ((row_span > 1) ? ' row-span' : '')}
                                style={style}>
                                <Typography className={cellSpecs[1]} style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    // CAT-1900 Commenting this out would cause long texts to break into multiple lines if necessary
                                    // whiteSpace: 'nowrap'
                                }}>{cellSpecs[3]}</Typography>
                            </TableCell>
                        })}
                    </TableRow>
                )}
                {data.length === 0 &&
                    <TableRow className="no-data">
                        <TableCell colSpan={headers.length}>
                            No data
                        </TableCell>
                    </TableRow>
                }
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        colSpan={headers.length}
                        count={data?.length || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        showFirstButton={true}
                        showLastButton={true}
                        SelectProps={{
                            inputProps: {'aria-label': 'rows per page'},
                            native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    </TableContainer>
})
